.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400; /* Regular weight */
  src: url('./assets/fonts/Montserrat/static/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600; /* SemiBold weight */
  src: url('./assets/fonts/Montserrat/static/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700; /* Bold weight */
  src: url('./assets/fonts/Montserrat/static/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400; /* Regular italic */
  src: url('./assets/fonts/Montserrat/static/Montserrat-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600; /* SemiBold italic */
  src: url('./assets/fonts/Montserrat/static/Montserrat-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700; /* Bold italic */
  src: url('./assets/fonts/Montserrat/static/Montserrat-BoldItalic.ttf') format('truetype');
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400; /* Default regular weight */
  font-style: normal;
  /* width: 1280px; */
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.dashed-border .highcharts-plot-border {
  stroke-dasharray: 5, 5; /* Create a dashed effect */
  stroke: #DFDFDF; 
  stroke-width: 2; 
}

.hide-ticks .highcharts-tick {
  stroke-width: 0;
}

.hide-scrollbar .highcharts-scrollbar {
  opacity: 0;
}


/* Shaking Widgets when drag enabled animation */

@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-2px); }
  50% { transform: translateX(2px); }
  75% { transform: translateX(-2px); }
  100% { transform: translateX(0); }
}

.shake-animation {
  animation: shake 0.5s infinite;
}