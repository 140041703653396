
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400; /* Regular weight */
  src: url('./assets/fonts/Montserrat/static/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600; /* SemiBold weight */
  src: url('./assets/fonts/Montserrat/static/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700; /* Bold weight */
  src: url('./assets/fonts/Montserrat/static/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400; /* Regular italic */
  src: url('./assets/fonts/Montserrat/static/Montserrat-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600; /* SemiBold italic */
  src: url('./assets/fonts/Montserrat/static/Montserrat-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700; /* Bold italic */
  src: url('./assets/fonts/Montserrat/static/Montserrat-BoldItalic.ttf') format('truetype');
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

body {

  font-family: 'Montserrat', sans-serif;
  font-weight: 400; /* Default regular weight */
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



  /* For WebKit-based browsers (Chrome, Safari, Opera) */
  ::-webkit-scrollbar {
    display: none;
  }
  
  /* For Firefox */
  * {
    scrollbar-width: none; /* Firefox */
  }
  
  /* For Internet Explorer and Edge */
  * {
    -ms-overflow-style: none; 
  }