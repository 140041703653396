/* .gridjs-footer button,
.gridjs-head button {
  background-color: transparent;
  background-image: none;
  border: none;
  cursor: pointer;
  margin: 0;
  outline: none;
  padding: 0;
} */
/* .gridjs-temp {
  position: relative;
} */
/* .gridjs-head {
  margin-bottom: 5px;
  padding: 5px 1px;
  width: 100%;
} */
/* .gridjs-head:after {
  clear: both;
  content: "";
  display: block;
} */
.gridjs-head:empty {
  border: none;
  padding: 0;
}
/* .gridjs-container {
  color: #000;
  display: inline-block;
  overflow: hidden;
  padding: 2px;
  position: relative;
  z-index: 0;
} */
/* .gridjs-footer {
  background-color: #fff;
  border-bottom-width: 1px;
  border-color: #e5e7eb;
  border-radius: 0 0 8px 8px;
  border-top: 1px solid #e5e7eb;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.26);
  display: block;
  padding: 12px 24px;
  position: relative;
  width: 100%;
  z-index: 5;
} */
/* .gridjs-footer:empty {
  border: none;
  padding: 0;
} */
input.gridjs-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border: 1px solid #d2d6dc;
  border-radius: 5px;
  font-size: 14px;
  line-height: 1.45;
  outline: none;
  padding: 10px 13px;
}
input.gridjs-input:focus {
  border-color: #9bc2f7;
  box-shadow: 0 0 0 3px rgba(149, 189, 243, 0.5);
}
.gridjs-pagination {
  margin-top: 20px;
  color: #3d4044;
}
.gridjs-pagination:after {
  clear: both;
  content: "";
  display: block;
}
.gridjs-pagination .gridjs-summary {
  float: left;
  margin-top: 5px;
}
.gridjs-pagination .gridjs-pages {
  float: center;
}
.gridjs-pagination .gridjs-pages button {
  height: 24px;
  width: 24px;
  overflow: hidden;
  color: #9BABBD;
  font-size: 14px;
  font-weight: 500;
  background-color: #fff;
  border: 1px solid #DDE3E8;
  border-radius: 6px;
  margin: 0 12px;
  outline: none;
  /* padding: 10px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.gridjs-pagination .gridjs-pages button:focus {
  border-right: 1px solid #d2d6dc;
  box-shadow: 0 0 0 2px rgba(149, 189, 243, 0.5);
  margin-right: -1px;
  position: relative;
}
.gridjs-pagination .gridjs-pages button:hover {
  background-color: #f7f7f7;
  color: #3c4257;
  outline: none;
}
.gridjs-pagination .gridjs-pages button:disabled,
.gridjs-pagination .gridjs-pages button:hover:disabled,
.gridjs-pagination .gridjs-pages button[disabled] {
  background-color: #fff;
  color: #6b7280;
  cursor: default;
}
.gridjs-pagination .gridjs-pages button.gridjs-spread {
  background-color: #fff;
  box-shadow: none;
  cursor: default;
}
.gridjs-pagination .gridjs-pages button.gridjs-currentPage {
  background-color: #8CC73B;
  color: #fff;
  font-weight: 500;
}
.gridjs-pagination .gridjs-pages button:last-child {
  border-bottom-right-radius: 6px;
  border-right: 1px solid #d2d6dc;
  border-top-right-radius: 6px;
  border-color: transparent;
}
.gridjs-pagination .gridjs-pages button:first-child {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  border-color: transparent;
}
.gridjs-pagination .gridjs-pages button:last-child:focus {
  margin-right: 0;
}
button.gridjs-sort {
  position: relative;
  right: 6px;
  top : -10px;
  background-color: transparent;
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  cursor: pointer;
  float: right;
  height: 5px; /* 15px */
  margin: 0;
  outline: none;
  padding: 0;
  /* width: 13px; */
}

button.gridjs-sort-neutral {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MDEuOTk4IiBoZWlnaHQ9IjQwMS45OTgiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQwMS45OTggNDAxLjk5OCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTczLjA5MiAxNjQuNDUyaDI1NS44MTNjNC45NDkgMCA5LjIzMy0xLjgwNyAxMi44NDgtNS40MjQgMy42MTMtMy42MTYgNS40MjctNy44OTggNS40MjctMTIuODQ3cy0xLjgxMy05LjIyOS01LjQyNy0xMi44NUwyMTMuODQ2IDUuNDI0QzIxMC4yMzIgMS44MTIgMjA1Ljk1MSAwIDIwMC45OTkgMHMtOS4yMzMgMS44MTItMTIuODUgNS40MjRMNjAuMjQyIDEzMy4zMzFjLTMuNjE3IDMuNjE3LTUuNDI0IDcuOTAxLTUuNDI0IDEyLjg1IDAgNC45NDggMS44MDcgOS4yMzEgNS40MjQgMTIuODQ3IDMuNjIxIDMuNjE3IDcuOTAyIDUuNDI0IDEyLjg1IDUuNDI0ek0zMjguOTA1IDIzNy41NDlINzMuMDkyYy00Ljk1MiAwLTkuMjMzIDEuODA4LTEyLjg1IDUuNDIxLTMuNjE3IDMuNjE3LTUuNDI0IDcuODk4LTUuNDI0IDEyLjg0N3MxLjgwNyA5LjIzMyA1LjQyNCAxMi44NDhMMTg4LjE0OSAzOTYuNTdjMy42MjEgMy42MTcgNy45MDIgNS40MjggMTIuODUgNS40MjhzOS4yMzMtMS44MTEgMTIuODQ3LTUuNDI4bDEyNy45MDctMTI3LjkwNmMzLjYxMy0zLjYxNCA1LjQyNy03Ljg5OCA1LjQyNy0xMi44NDggMC00Ljk0OC0xLjgxMy05LjIyOS01LjQyNy0xMi44NDctMy42MTQtMy42MTYtNy44OTktNS40Mi0xMi44NDgtNS40MnoiLz48L3N2Zz4=");
  background-position-y: center;
  opacity: 0.3;
  background-size: 10px;
}
button.gridjs-sort-asc {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyOTIuMzYyIiBoZWlnaHQ9IjI5Mi4zNjEiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI5Mi4zNjIgMjkyLjM2MSIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTI4Ni45MzUgMTk3LjI4NyAxNTkuMDI4IDY5LjM4MWMtMy42MTMtMy42MTctNy44OTUtNS40MjQtMTIuODQ3LTUuNDI0cy05LjIzMyAxLjgwNy0xMi44NSA1LjQyNEw1LjQyNCAxOTcuMjg3QzEuODA3IDIwMC45MDQgMCAyMDUuMTg2IDAgMjEwLjEzNHMxLjgwNyA5LjIzMyA1LjQyNCAxMi44NDdjMy42MjEgMy42MTcgNy45MDIgNS40MjUgMTIuODUgNS40MjVoMjU1LjgxM2M0Ljk0OSAwIDkuMjMzLTEuODA4IDEyLjg0OC01LjQyNSAzLjYxMy0zLjYxMyA1LjQyNy03Ljg5OCA1LjQyNy0xMi44NDdzLTEuODE0LTkuMjMtNS40MjctMTIuODQ3eiIvPjwvc3ZnPg==");
  background-position-y: 35%;
  background-size: 7px;
}
button.gridjs-sort-desc {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyOTIuMzYyIiBoZWlnaHQ9IjI5Mi4zNjIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI5Mi4zNjIgMjkyLjM2MiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTI4Ni45MzUgNjkuMzc3Yy0zLjYxNC0zLjYxNy03Ljg5OC01LjQyNC0xMi44NDgtNS40MjRIMTguMjc0Yy00Ljk1MiAwLTkuMjMzIDEuODA3LTEyLjg1IDUuNDI0QzEuODA3IDcyLjk5OCAwIDc3LjI3OSAwIDgyLjIyOGMwIDQuOTQ4IDEuODA3IDkuMjI5IDUuNDI0IDEyLjg0N2wxMjcuOTA3IDEyNy45MDdjMy42MjEgMy42MTcgNy45MDIgNS40MjggMTIuODUgNS40MjhzOS4yMzMtMS44MTEgMTIuODQ3LTUuNDI4TDI4Ni45MzUgOTUuMDc0YzMuNjEzLTMuNjE3IDUuNDI3LTcuODk4IDUuNDI3LTEyLjg0NyAwLTQuOTQ4LTEuODE0LTkuMjI5LTUuNDI3LTEyLjg1eiIvPjwvc3ZnPg==");
  background-position-y: 65%;
  background-size: 7px;
}
button.gridjs-sort:focus {
  outline: none;
}
table.gridjs-table {
  border-collapse: collapse;
  display: table;
  /* margin: -10px; */
  position: relative;
  /* top: -40px; */
  max-width: 100%;
  /* overflow: scroll; */
  padding: 0;
  margin: 0;
  /* table-layout: fixed; */
  /* text-align: left; */
  width: 100%;
}
/* .gridjs-tbody,
td.gridjs-td {
  background-color: #fff;
} */
/* td.gridjs-td {
  border: 1px solid #e5e7eb;
  box-sizing: content-box;
  padding: 12px 24px;
} */
/* td.gridjs-td:first-child {
  border-left: none;
} */
/* td.gridjs-td:last-child {
  border-right: none;
} */
/* td.gridjs-message {
  text-align: center;
} */
th.gridjs-th {
  /* background-color: #f9fafb; */
  /* border: 1px solid #e5e7eb; */
  border-top: none;
  /* box-sizing: border-box; */
  /* color: #6b7280; */
  outline: none;
  padding: 3px 0px 0px 0px;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}
th.gridjs-th .gridjs-th-content {
  /* float: left; */
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
th.gridjs-th-sort {
  cursor: pointer;
}
th.gridjs-th-sort .gridjs-th-content {
  width: calc(100% - 15px);
}
/* th.gridjs-th-sort:focus,
th.gridjs-th-sort:hover {
  background-color: #e5e7eb;
} */
th.gridjs-th-fixed {
  /* box-shadow: 0 1px 0 0 #e5e7eb; */
  position: sticky;
}
@supports (-moz-appearance: none) {
  th.gridjs-th-fixed {
    box-shadow: 0 0 0 1px #e5e7eb;
  }
}
/* th.gridjs-th:first-child {
  border-left: none;
} */
/* th.gridjs-th:last-child {
  border-right: none;
} */
/* .gridjs-tr {
  border: none;
} */
.gridjs-tr-selected td {
  background-color: #ebf5ff;
}
/* .gridjs-tr:last-child td {
  border-bottom: 0;
} */
/* .gridjs *,
.gridjs :after,
.gridjs :before {
  box-sizing: border-box;
} */
/* .gridjs-wrapper {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-color: #e5e7eb;
  border-radius: 8px 8px 0 0;
  border-top-width: 1px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.26);
  display: block;
  overflow: auto;
  position: relative;
  width: 100%;
  z-index: 1;
} */
/* .gridjs-wrapper:nth-last-of-type(2) {
  border-bottom-width: 1px;
  border-radius: 8px;
} */
.gridjs-search {
  float: right;
}
.gridjs-search-input {
  width: 150px;
  height: 30px;
  margin-right: 10px;
  position: relative;
  top: -48px;  /************i changes***********/
}
/* .gridjs-loading-bar {
  background-color: #fff;
  opacity: 0;
  z-index: 10;
}
.gridjs-loading-bar,
.gridjs-loading-bar:after {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.gridjs-loading-bar:after {
  animation: shimmer 2s infinite;
  background-image: linear-gradient(
    90deg,
    hsla(0, 0%, 80%, 0),
    hsla(0, 0%, 80%, 0.2) 20%,
    hsla(0, 0%, 80%, 0.5) 60%,
    hsla(0, 0%, 80%, 0)
  );
  content: "";
  transform: translateX(-100%);
}
@keyframes shimmer {
  to {
    transform: translateX(100%);
  }
} */
.gridjs-td .gridjs-checkbox {
  cursor: pointer;
  display: block;
  margin: auto;
}
.gridjs-resizable {
  bottom: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 5px;
}
.gridjs-resizable:hover {
  background-color: #9bc2f7;
  cursor: ew-resize;
}


/* ****************************Custom********************* */

/* .plant-table {
  overflow: scroll;
} */

.plant-table .gridjs-thead {
    background: #DDE3E8;
    color: #062D59;
    font-weight: 600;
    font-size: 14px;
    border-bottom: none;
    overflow-x: none !important;
  }
/* 
  @media (prefers-color-scheme: dark) {
    body .plant-table .gridjs-thead {
      background: #262B27;
    }
  
  } */
  
  .plant-table .gridjs-th {
    text-align: center;
    /* line-height: 12px; */
    /* width: 106px;
    padding: -10px 0; */
    /* overflow-x: auto; */
    /* gap: 17px; */
  }
  
  /* .plant-table .gridjs-th::after {
    content: "||";
    position: relative;
    right: -15px;
    bottom: 15px;
    float: right;
  } */
  
  .plant-table .gridjs-tr {
    border-top: none;
  }
  
  .plant-table .gridjs-td {
    border : 1px solid #DDE3E8;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: inherit;
    height: 40px;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
  
  .plant-table .gridjs-td:first-child {
    color: inherit;
    font-weight: 400;
    text-align: left;
  }
  
  /* Pagination Styles from mermaid.css */
  .gridjs-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 220px; */
  }


  .plant-table-container {
    width: 100%;
    height: 100%;
    /* overflow-x: auto;
    overflow-x: scroll; */
  }
  /* Full Screen Style */
  .plant-table-container:fullscreen {
    width: 100vw;
    height: 100vh;
    background: linear-gradient(360deg, #FAFAFA -12.5%, #D1D8DD 187.5%);
  }

  .full-screen-button {
    float: right;
    position: relative;
    bottom: 35px;
  }

  /* Pagination Style */
  .gridjs-pagination__prev, .gridjs-pagination__next {
    text-indent: -9999px; /* Hide text */
  }

  /* Add left/right icons using pseudo-elements */
.gridjs-pagination__prev::before {
  content: ""; /* Replace with your icon (e.g., Font Awesome) */
  text-indent: 0;
  height: 8px; /* Adjust size as needed */
  width: 8px;
  position: absolute;
  top: 25%;
  left: 25%; /* Center horizontally */
  transform: translateX(-50%); /* Center alignment */
  transform: rotate(45deg);
  border : 2px solid #9BABBD;
  border-right: 0px;
  border-top: 0px;
}

.gridjs-pagination__next::before {
  content: "";
  text-indent: 0;
  height: 8px; /* Adjust size as needed */
  width: 8px;
  position: absolute;
  top: 25%;
  left: 32.5%; /* Center horizontally */
  transform: translateX(-50%); /* Center alignment */
  transform: rotate(-45deg);
  border : 2px solid #9BABBD;
  border-left: 0px;
  border-top: 0px;
}

.gridjs-pagination__prev,
.gridjs-pagination__next {
  position: relative; /* Enable positioning for pseudo-elements */
}


  @media (min-height : 1000px) {
    .plant-table .gridjs-thead .plant-table .gridjs-td {
      font-size: 17px;
    }

    /* button.gridjs-sort {
      top : -23px
    } */

    /* .plant-table .gridjs-th::after {
      bottom: 22px;
    } */
  }